

























































































































































































import { Component, Prop, Watch, Emit } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import stacksList from './stacksList.vue'

@Component({
  name: 'LinkList',
  components: {
    stacksList,
  },
})
export default class LinkList extends VueBase {
  @Prop() tree: any
  @Prop() textTopNumber: any
  @Prop() newBetaClick: any
  @Prop() index: any

  private stacks:any = []
  private radioValue:any = 'all'
  private handleChange(e: any,stackItems:any) {
    console.log(" ======= this is change radio values ==== ")
    if (e == 'all') {
      this.stacks = stackItems
    } else {
      this.stacks = stackItems.filter((item:any) => item.code_belong == e)
    }
		this.radioValue = e
  }
  @Watch('textTopNumber', { immediate: true, deep: true })
  onDataChanged() {
    // console.log('textTopNumber', this.textTopNumber)
    // 修复建议距离顶部高度
    this.measure()
  }
  @Emit('changetopNumber')
  private changetopNumber(index: any) {
    return index
  }
  private parenTopNumber: any = 0
  private measure() {
    if (this.newBetaClick) {
      return
    }
    this.$nextTick(() => {
      this.parenTopNumber =
        this.$refs[
          'parenTop' + this.index + (this.tree.length - 1)
        ]?.[0]?.getBoundingClientRect()?.top || 0
      console.log(
        'this.parenTopNumber',
        this.$refs[
          'parenTop' + this.index + (this.tree.length - 1)
        ]?.[0].getBoundingClientRect()?.top
      )
    })
  }
  get reacthight() {
    if (this.newBetaClick) {
      return 0
    }
    // console.log('reacthight', this.textTopNumber - this.parenTopNumber + 32)
    return this.textTopNumber - this.parenTopNumber + 32
  }
  private goChangetopNumber() {
    this.changetopNumber(this.index)
  }
  private openClick(vItem: any) {
    this.$set(vItem, 'open', !vItem.open)
    this.changetopNumber(this.index)
    this.$nextTick(() => {
      this.changetopNumber(this.index)
    })
  }

  created() {
    // console.log('tree', this.tree)
    this.tree.forEach((element: any) => {
      this.$set(element, 'open', false)
    })
    this.measure()
  }
  private levelColor(level: any) {
    switch (level) {
      case '危险方法':
        return '#ee5769'
      case '污点来源方法':
        return '#60d3ba'
      case '传播方法':
        return '#579bfe'
    }
  }

  private levelColor1(level: any) {
    switch (level) {
      case '危险方法':
        return '#fbebe7'
      case '污点来源方法':
        return '#e5fce5'
      case '传播方法':
        return '#c7defc'
    }
  }

  private levelLabel(level: any) {
    switch (level) {
      case 2:
        return '危险函数执行'
      case 0:
        return '外部参数传入'
      case 1:
        return '数据调用传播'
    }
  }

  private bgColor(level_type: number) {
    switch (level_type) {
      case 1:
        return 'rgba(229, 99, 99, 0.1)'
      case 2:
        return 'rgba(244, 158, 11, 0.1)'
      case 3:
        return 'rgba(47, 144, 234, 0.1)'
      case 4:
        return 'rgba(172, 180, 196, 0.1)'
      case 5:
        return 'rgba(219, 219, 219, 0.1)'
    }
  }
}
